import { rotationsTypes } from '@clickadilla/components/constants/rotations-types.js'
import { applicationRepository } from '@/services/repository-factory.js'
import Application from '@/services/classes/Application.js'
import handleErrors from '@/services/handleErrors.js'
import guardSections from '@/types/guard-sections.js'
import startPwa from '@/startPwa.js'
import setFavicons from '@/services/utils/set-favicon.js'
import gtm from '@/plugins/gtm.js'
import routeNames from '@/types/route-names.js'

const { gtmCreate } = gtm

export default {
  namespaced: true,
  state: {
    backRouteName: '',
    manifest: {},
    pwaIsStarted: false,
    gtmIsCreated: false,
    notificationCategoryGroups: [],
    notificationCategories: [],
    isSidebarShown: false,
    applicationIsAuth: false,
    applicationDataFetched: false,
    isMiniSidebarShown: false,
    maxCampaignUpdatingRuleConditions: null,
    campaignUpdatingRuleTypes: [],
    campaignUpdatingRuleConditionFields: [],
    campaignUpdatingRuleOperations: ['greater', 'equal', 'less'],
    loading: false,
    clientAdFormats: [],
    placementTypes: [],
    limitTypes: ['money', 'impressions', 'clicks'],
    defaultUniquesCap: null,
    defaultUniquesTtl: null,
    connectionTypes: [
      { type: 'all', name: 'All types' },
      { type: 'wifi', name: 'LAN & Wi-Fi' },
      { type: 'accept', name: 'Mobile carriers' }
    ],
    locale: 'en',
    locales: [
      { value: 'en', label: 'English', flagIcon: '$en-flag' },
      { value: 'ru', label: 'Russian', flagIcon: '$ru-flag' },
      { value: 'es', label: 'Spanish', flagIcon: '$es-flag' },
      { value: 'de', label: 'Deutsch', flagIcon: '$de-flag' },
      { value: 'jp', label: 'Japanese', flagIcon: '$jp-flag' }
    ],
    minLimitInMoney: null,
    minLimitInImpressions: null,
    minLimitInClicks: null,
    maxWebPushSubscriptionDays: null,
    maxSessionCount: 50,
    companyCardIoLink: '',
    cardIoLink: '',
    undefinedSiteId: '',
    undefinedSpotTdsId: '',
    smartBiddingByAdFormat: {},
    serverTime: '',
    minAddFundsAmount: null,
    twoFactorAuthenticationMethods: [],
    paymentMethods: [],
    cardVerificationRequiredForPayments: false,
    limitTypesByPricingModel: {},
    adTypes: {},

    adsDataRequirements: {
      iosCalendar: {
        title: 60,
        description: 160
      },
      push: {
        title: 25,
        bodyWithoutImage: 100,
        bodyWithImage: 35
      },
      directLink: {
        title: 18
      },
      gallery: {
        contentFeedName: 23
      },
      nativeAd: {
        title: 45,
        description: 80
      }
    },
    defaultRotationHoursThreshold: null,
    defaultRotationImpressionsThreshold: null,
    directLinkIcons: [],
    inStreamAdSkipTimeOptions: [],
    mediaFileSizeLimits: {},
    trafficChartPricingModels: [],
    trafficQualityTypes: [],
    rotationTypes: [],
    adNetwork: {
      adFormats: [],
      name: '',
      guardSections: [],
      requireMainstreamNativeAds: 0,
      targetTypes: [],
      showSiteDomains: true,
      crispWebsiteId: null,
      settings: {
        channelsSettings: {},
        apiSelect: '',
        apiUrl: '',
        enablePwa: false,
        renameInPageFormat: false,
        themes: {},
        socials: {},
        images: {},
        favicons: {},
        pwaFavicon: {},
        helpUrls: {},
        helpVideosUrls: {},
        dashboardGallery: false,
        spentThisWeek: false,
        showVerificationBanner: true,
        payments: false,
        enableReferralBanners: false,
        referralBanners: {},
        sidebarWidget: {},
        gtmId: '',
        locales: [],
        welcomeScreen: {
          type: 'three-screen',
          imageUrl: '',
          videoUrl: ''
        },
        liveChat: {
          type: '',
          intercom: {},
          tawk: {}
        },
        ioLink: '',
        adsSpots: {
          marketplace: { type: null },
          dashboardFirst: { type: null },
          dashboardSecond: { type: null },
          dashboardThird: { type: null },
          dashboardFourth: { type: null }
        },
        inPagePreviewTagId: null,
        stripeRedirectUrl: '',
        enablePanvyDetails: false,
        defaultAdFormatTargets: {},
        orderAdFormatSortingGroups: [],
        orderAdFormatTypes: [],
        currencySignIsShown: false
      }
    },
    trafficProviderMethodTypes: [],
    trafficProviderPricingModels: [],
    trafficProviderVerticalTypes: [],
    flatDealAdFormatIds: [],
    flatDealCountryGroups: [],
    flatDealDefaultLimitType: null,
    flatDealDefaultValueKey: null,
    flatDealTiers: [],
    flatDealTrafficTypes: [],
    flatDealApplicationLimitTypes: [],
    trafficQualityTypesName: [
      { label: 'Medium', value: 'medium' },
      { label: 'High', value: 'high' }
    ],
    providerMethodTypes: [
      { value: 'xml', text: 'XML' },
      { value: 'rtb', text: 'oRTB' }
    ],
    defaultInPageSkinId: null
  },
  mutations: {
    SET_BACK_ROUTE_NAME: (state, backRouteName) => {
      state.backRouteName = backRouteName
    },
    SET_APPLICATION_IS_AUTH: (state, val) => {
      state.applicationIsAuth = val
    },
    SET_APPLICATION_DATA_FETCHED: (state, val) => {
      state.applicationDataFetched = val
    },
    SET_MEDIA_FILE_SIZE_LIMITS: (state, val) => {
      state.mediaFileSizeLimits = val
    },
    SET_LOADING: (state, val) => {
      state.loading = val
    },
    SET_CLIENT_AD_FORMATS: (state, val) => {
      state.clientAdFormats = val
    },
    SET_MANIFEST_DATA: (state, val) => {
      state.manifest = val
    },
    SET_PLACEMENT_TYPES: (state, val) => {
      state.placementTypes = val
    },
    SET_AD_TYPES: (state, val) => {
      state.adTypes = val
    },
    SET_CAMPAIGN_UPDATING_RULE_TYPES: (state, val) => {
      state.campaignUpdatingRuleTypes = val
    },
    SET_CAMPAIGN_UPDATING_RULE_CONDITION_FIELDS: (state, val) => {
      state.campaignUpdatingRuleConditionFields = val
    },
    SET_ROTATION_TYPES: (state, val) => {
      state.rotationTypes = val
    },
    SET_MIN_LIMIT_IN_MONEY: (state, val) => {
      state.minLimitInMoney = val
    },
    SET_MIN_LIMIT_IN_IMPRESSIONS: (state, val) => {
      state.minLimitInImpressions = val
    },
    SET_MIN_LIMIT_IN_CLICKS: (state, val) => {
      state.minLimitInClicks = val
    },
    SET_COMPANY_CARD_IO_LINK: (state, val) => {
      state.companyCardIoLink = val
    },
    SET_CARD_IO_LINK: (state, val) => {
      state.cardIoLink = val
    },
    SET_UNDEFINED_SITE_ID: (state, undefinedSiteId) => {
      state.undefinedSiteId = undefinedSiteId
    },
    SET_MAX_WEB_PUSH_SUBSCRIPTION_DAYS: (state, maxWebPushSubscriptionDays) => {
      state.maxWebPushSubscriptionDays = maxWebPushSubscriptionDays
    },
    SET_UNDEFINED_SPOT_TDS_ID: (state, undefinedSpotTdsId) => {
      state.undefinedSpotTdsId = undefinedSpotTdsId
    },
    SET_SMART_BIDDING_BY_AD_FORMAT: (state, val) => {
      state.smartBiddingByAdFormat = val
    },
    SET_SERVER_TIME: (state, val) => {
      state.serverTime = val
    },
    SET_TWO_FACTOR_AUTHENTICATION_METHODS: (state, val) => {
      state.twoFactorAuthenticationMethods = val
    },
    SET_PWA_IS_STARTED(state, val) {
      state.pwaIsStarted = val
    },
    SET_MIN_ADD_FUNDS_AMOUNT: (state, val) => {
      state.minAddFundsAmount = val
    },
    SET_PAYMENT_METHODS: (state, val) => {
      state.paymentMethods = val
    },
    SET_CARD_VERIFICATION_REQUIRED_FOR_PAYMENTS: (state, val) => {
      state.cardVerificationRequiredForPayments = val
    },
    SET_LIMIT_TYPES_BY_PRICING_MODELS: (state, val) => {
      state.limitTypesByPricingModel = val
    },
    SET_DEFAULT_ROTATION_HOURS_THRESHOLD: (state, val) => {
      state.defaultRotationHoursThreshold = val
    },
    SET_DEFAULT_ROTATION_IMPRESSIONS_THRESHOLD: (state, val) => {
      state.defaultRotationImpressionsThreshold = val
    },
    SET_DEFAULT_UNIQUES_CAP: (state, val) => {
      state.defaultUniquesCap = val
    },
    SET_MAX_CAMPAIGN_UPDATING_RULE_CONDITIONS: (state, val) => {
      state.maxCampaignUpdatingRuleConditions = val
    },
    SET_DEFAULT_UNIQUES_TTL: (state, val) => {
      state.defaultUniquesTtl = val
    },
    SET_DIRECT_LINK_ICONS: (state, val) => {
      state.directLinkIcons = val
    },
    SET_IN_STREAM_AD_SKIP_TIME_OPTIONS: (state, val) => {
      state.inStreamAdSkipTimeOptions = val
    },
    SET_IS_SIDEBAR_SHOWN: (state, val) => {
      state.isSidebarShown = val
    },
    SET_IS_MINI_SIDEBAR_SHOWN: (state, val) => {
      state.isMiniSidebarShown = val
    },
    SET_TRAFFIC_CHART_PRICING_MODELS: (state, trafficChartPricingModels) => {
      state.trafficChartPricingModels = trafficChartPricingModels
    },
    SET_TRAFFIC_QUALITY_TYPES: (state, trafficQualityTypes) => {
      state.trafficQualityTypes = trafficQualityTypes
    },
    SET_AD_NETWORK: (state, adNetwork) => {
      state.adNetwork = adNetwork
    },
    SET_NOTIFICATION_CATEGORY_GROUPS: (state, notificationCategoryGroups) => {
      state.notificationCategoryGroups = notificationCategoryGroups
    },
    SET_NOTIFICATION_CATEGORIES: (state, notificationCategories) => {
      state.notificationCategories = notificationCategories
    },
    SET_GTM_IS_CREATED: (state, gtmIsCreated) => {
      state.gtmIsCreated = gtmIsCreated
    },
    SET_LOCALE: (state, locale) => {
      state.locale = locale
    },
    SET_TRAFFIC_PROVIDER_METHOD_TYPES: (state, trafficProviderMethodTypes) => {
      state.trafficProviderMethodTypes = trafficProviderMethodTypes
    },
    SET_TRAFFIC_PROVIDER_PRICING_MODELS: (state, trafficProviderPricingModels) => {
      state.trafficProviderPricingModels = trafficProviderPricingModels
    },
    SET_TRAFFIC_PROVIDER_VERTICAL_TYPES: (state, trafficProviderVerticalTypes) => {
      state.trafficProviderVerticalTypes = trafficProviderVerticalTypes
    },
    SET_FLAT_DEAL_AD_FORMAT_IDS: (state, flatDealAdFormatIds) => {
      state.flatDealAdFormatIds = flatDealAdFormatIds
    },
    SET_FLAT_DEAL_TIERS: (state, flatDealTiers) => {
      state.flatDealTiers = flatDealTiers
    },
    SET_FLAT_DEAL_TRAFFIC_TYPES: (state, flatDealTrafficTypes) => {
      state.flatDealTrafficTypes = flatDealTrafficTypes
    },
    SET_FLAT_DEAL_COUNTRY_GROUPS: (state, flatDealCountryGroups) => {
      state.flatDealCountryGroups = flatDealCountryGroups
    },
    SET_FLAT_DEAL_DEFAULT_LIMIT_TYPE: (state, flatDealDefaultLimitType) => {
      state.flatDealDefaultLimitType = flatDealDefaultLimitType
    },
    SET_FLAT_DEAL_DEFAULT_VALUE_KEY: (state, flatDealDefaultValueKey) => {
      state.flatDealDefaultValueKey = flatDealDefaultValueKey
    },
    SET_FLAT_DEAL_APPLICATION_LIMIT_TYPES: (state, flatDealApplicationLimitTypes) => {
      state.flatDealApplicationLimitTypes = flatDealApplicationLimitTypes
    },
    SET_DEFAULT_IN_PAGE_SKIN_ID: (state, defaultInPageSkinId) => {
      state.defaultInPageSkinId = defaultInPageSkinId
    }
  },
  actions: {
    setBackRouteName({ commit }, val) {
      commit('SET_BACK_ROUTE_NAME', val)
    },
    setIsMiniSidebarShown({ commit }, val) {
      commit('SET_IS_MINI_SIDEBAR_SHOWN', val)
    },
    setIsSidebarShown({ commit }, val) {
      commit('SET_IS_SIDEBAR_SHOWN', val)
    },
    async fetchSettings({
      commit, dispatch, getters, state
    }, applicationIsAuth) {
      commit('SET_LOADING', true)
      try {
        const response = await applicationRepository.list()
        const settings = new Application(response)

        commit('SET_APPLICATION_IS_AUTH', applicationIsAuth)
        commit('SET_APPLICATION_DATA_FETCHED', true)

        commit('SET_CLIENT_AD_FORMATS', settings.clientAdFormats)
        commit('SET_AD_NETWORK', settings.adNetwork)

        // @ TODO REMOVE MOCK AFTER BACKEND BE READY
        commit('SET_MANIFEST_DATA', {
          name: settings.adNetwork.name,
          short_name: settings.adNetwork.name,
          theme_color: settings.adNetwork.settings.themes.light.primary,
          background_color: settings.adNetwork.settings.themes.black,
          icons: [
            {
              src: settings.adNetwork.settings.pwaFavicon['192x192'],
              sizes: '192x192',
              type: 'image/png'
            },
            {
              src: settings.adNetwork.settings.pwaFavicon['256x256'],
              sizes: '256x256',
              type: 'image/png'
            },
            {
              src: settings.adNetwork.settings.pwaFavicon['384x384'],
              sizes: '384x384',
              type: 'image/png'
            },
            {
              src: settings.adNetwork.settings.pwaFavicon['512x512'],
              sizes: '512x512',
              type: 'image/png'
            }
          ].filter(({ src }) => !!src),
          start_url: window.location.origin,
          display: 'standalone'
        })
        commit('SET_AD_TYPES', settings.adTypes)
        commit('SET_CAMPAIGN_UPDATING_RULE_TYPES', settings.campaignUpdatingRuleTypes)
        commit('SET_CAMPAIGN_UPDATING_RULE_CONDITION_FIELDS', settings.campaignUpdatingRuleConditionFields)
        commit('SET_DEFAULT_IN_PAGE_SKIN_ID', settings.defaultInPageSkinId)
        commit('SET_DEFAULT_UNIQUES_CAP', settings.defaultUniquesCap)
        commit('SET_DEFAULT_UNIQUES_TTL', settings.defaultUniquesTtl)
        commit('SET_DIRECT_LINK_ICONS', settings.directLinkIcons)
        commit('SET_IN_STREAM_AD_SKIP_TIME_OPTIONS', settings.inStreamAdSkipTimeOptions)
        commit('SET_LIMIT_TYPES_BY_PRICING_MODELS', settings.limitTypesByPricingModel)
        commit('SET_MEDIA_FILE_SIZE_LIMITS', settings.mediaFileSizeLimits)
        commit('SET_MIN_ADD_FUNDS_AMOUNT', settings.minAddFundsAmount)
        commit('SET_PAYMENT_METHODS', settings.paymentMethods)
        commit('SET_PLACEMENT_TYPES', settings.placementTypes)
        commit('SET_ROTATION_TYPES', settings.rotationTypes)
        commit('SET_CARD_IO_LINK', settings.settings.cardIoLink)
        commit('SET_UNDEFINED_SITE_ID', settings.settings.undefinedSiteId)
        commit('SET_UNDEFINED_SPOT_TDS_ID', settings.settings.undefinedSpotTdsId)
        commit('SET_MAX_WEB_PUSH_SUBSCRIPTION_DAYS', settings.settings.maxWebPushSubscriptionDays)
        commit(
          'SET_CARD_VERIFICATION_REQUIRED_FOR_PAYMENTS',
          settings.settings.cardVerificationRequiredForPayments
        )
        commit('SET_COMPANY_CARD_IO_LINK', settings.settings.companyCardIoLink)
        commit(
          'SET_DEFAULT_ROTATION_HOURS_THRESHOLD',
          settings.settings.defaultRotationHoursThreshold
        )
        commit(
          'SET_DEFAULT_ROTATION_IMPRESSIONS_THRESHOLD',
          settings.settings.defaultRotationImpressionsThreshold
        )
        commit('SET_MIN_LIMIT_IN_MONEY', settings.settings.minLimitInMoney)
        commit('SET_MIN_LIMIT_IN_IMPRESSIONS', settings.settings.minLimitInImpressions)
        commit('SET_MIN_LIMIT_IN_CLICKS', settings.settings.minLimitInClicks)
        commit('SET_SMART_BIDDING_BY_AD_FORMAT', settings.smartBiddingByAdFormatTypeAndPricingModel)
        commit('SET_SERVER_TIME', settings.serverTime)
        commit('SET_TRAFFIC_CHART_PRICING_MODELS', settings.trafficChartPricingModels)
        commit('SET_TRAFFIC_QUALITY_TYPES', settings.trafficQualityTypes)
        commit('SET_TWO_FACTOR_AUTHENTICATION_METHODS', settings.twoFactorAuthenticationMethods)
        commit('SET_NOTIFICATION_CATEGORY_GROUPS', settings.notificationCategoryGroups)
        commit('SET_NOTIFICATION_CATEGORIES', settings.notificationCategories)
        commit('SET_TRAFFIC_PROVIDER_METHOD_TYPES', settings.trafficProviderMethodTypes)
        commit('SET_TRAFFIC_PROVIDER_PRICING_MODELS', settings.trafficProviderPricingModels)
        commit('SET_TRAFFIC_PROVIDER_VERTICAL_TYPES', settings.trafficProviderVerticalTypes)
        commit('SET_FLAT_DEAL_AD_FORMAT_IDS', settings.flatDealAdFormatIds)
        commit('SET_FLAT_DEAL_TIERS', settings.flatDealTiers)
        commit('SET_FLAT_DEAL_TRAFFIC_TYPES', settings.flatDealTrafficTypes)
        commit('SET_MAX_CAMPAIGN_UPDATING_RULE_CONDITIONS', settings.maxCampaignUpdatingRuleConditions)
        commit('SET_FLAT_DEAL_COUNTRY_GROUPS', settings.flatDealCountryGroups)
        commit('SET_FLAT_DEAL_DEFAULT_LIMIT_TYPE', settings.flatDealDefaultLimitType)
        commit('SET_FLAT_DEAL_DEFAULT_VALUE_KEY', settings.flatDealDefaultValueKey)
        commit('SET_FLAT_DEAL_APPLICATION_LIMIT_TYPES', settings.flatDealApplicationLimitTypes)

        if (getters.getLocales.some(({ value }) => value === localStorage.locale)) {
          dispatch('setLocale', localStorage.locale)
        }

        if (!state.pwaIsStarted) {
          await startPwa(settings.adNetwork.settings.enablePwa)
          commit('SET_PWA_IS_STARTED', true)
        }

        if (!state.gtmIsCreated && !!settings.adNetwork.settings.gtmId) {
          gtmCreate(window, document, 'script', 'dataLayer', settings.adNetwork.settings.gtmId)
          commit('SET_GTM_IS_CREATED', true)
        }

        setFavicons(settings.adNetwork.settings.favicons)
      } catch (error) {
        handleErrors(error)
      }
      commit('SET_LOADING', false)
    },
    setLocale({ commit }, locale) {
      commit('SET_LOCALE', locale)
    },
    setVuetifyColor({ state }, vuetify) {
      const themeLight = state.adNetwork.settings.themes.light
      vuetify.theme.themes.light = {
        ...vuetify.theme.themes.light,
        black: themeLight.black,
        'cpc-color': themeLight.cpcColor,
        'cpc-goal-color': themeLight.cpcGoalColor,
        'cpm-color': themeLight.cpmColor,
        'cpm-goal-color': themeLight.cpmGoalColor,
        error: themeLight.error,
        info: themeLight.info,
        'info-lighten': themeLight.infoLighten,
        primary: themeLight.primary,
        'primary-lighten': themeLight.primaryLighten,
        secondary: themeLight.secondary,
        'secondary-darken': themeLight.secondaryDarken,
        'secondary-lighten': themeLight.secondaryLighten,
        warning: themeLight.warning,
        white: themeLight.white,
        'cpa-color': themeLight.cpaColor
      }
    }
  },
  getters: {
    getCasedFlatDealDefaultValueKey: (state) => state.flatDealDefaultValueKey
      .replace(/(_\w)/g, (match) => match[1].toUpperCase()),
    defaultUniquesTypes: (state) => ({
      cap: state.defaultUniquesCap,
      ttl: state.defaultUniquesTtl
    }),
    getLocales: (state) => state.locales.filter(({ value }) => state.adNetwork.settings.locales.includes(value)),
    getMainRouteName: (state) => {
      if (state.adNetwork.guardSections.find(({ name }) => name === guardSections.DASHBOARD)) {
        return routeNames.DASHBOARD
      }
      if (state.adNetwork.guardSections.find(({ name }) => name === guardSections.RTB_STATISTICS)) {
        return routeNames.RTB_STATISTICS
      }
      return routeNames.PERSONAL
    },
    getAdNetworkImages: (state) => state.adNetwork.settings.images,
    helpVideosUrls: (state) => state.adNetwork.settings.helpVideosUrls,
    helpUrls: (state) => state.adNetwork.settings.helpUrls,
    maxImageSize: (state) => `${state.mediaFileSizeLimits['image/jpeg'] / 1024}KB`,
    maxVideoSize: (state) => `${state.mediaFileSizeLimits['video/mp4'] / (1024 * 1024)}MB`,
    maxZipSize: (state) => `${state.mediaFileSizeLimits['application/zip'] / (1024 * 1024)}MB`,
    getBannersSizes: (state, getter) => [
      ...new Set(
        getter.getAdFormatsByAdNetwork
          .filter((adFormat) => adFormat.type === 'banner')
          .map((adFormat) => adFormat.size)
      )
    ],
    interstitialsSizes: (state, getter) => getter.getAdFormatsByAdNetwork
      .filter((adFormat) => adFormat.type === 'interstitial')
      .map((adFormat) => adFormat.size),
    interstitialAdTypes: (state) => state.adTypes.interstitial || [],
    bannerAdTypes: (state) => state.adTypes.banner || [],
    inStreamAdTypes: (state) => state.adTypes.inStream || [],
    skipTimeOptions: (state) => state.inStreamAdSkipTimeOptions,
    getAdFormatsByAdNetwork: (state) => {
      const adFormatsFiltered = state.adNetwork.adFormats
        .sort((a, b) => {
          const value1 = state.adNetwork.settings.orderAdFormatTypes.findIndex((value) => value === a.type)
          const value2 = state.adNetwork.settings.orderAdFormatTypes.findIndex((value) => value === b.type)
          return value1 - value2
        })
      return adFormatsFiltered.map((adFormat) => ({
        ...adFormat,
        targetTypes: adFormat.targetTypes.filter(({ type }) => state.adNetwork.targetTypes.some((targetType) => targetType.type === type))
      }))
    },
    getChannelsByAdNetwork: (state, getter) => ({
      isCustomChannelsTitle: state.adNetwork.settings.channelsSettings.isCustomChannelsTitle,
      channels: state.adNetwork.settings.channelsSettings.channels
        .map((channel) => ({
          ...channel,
          adFormatsIds: channel.adFormatsIds.filter((adFormatId) => getter.getAdFormatsByAdNetwork.some(({ id }) => id === adFormatId))
        }))
    }),
    getAdFormatTypesByAdNetwork: (_, getter) => [...new Set(getter.getAdFormatsByAdNetwork.map((adFormat) => adFormat.type))],
    getRotationTypes: (state) => {
      const labels = { [rotationsTypes.SMART]: 'On', [rotationsTypes.RANDOM]: 'Off' }
      return state.rotationTypes.map((type) => ({ value: type, text: labels[type] || type }))
    },
    getTrafficProviderMethodTypes: (state) => state.trafficProviderMethodTypes.map((type) => {
      const foundProviderMethodType = state.providerMethodTypes.find((item) => item.value === type)
      return foundProviderMethodType ?? { value: type, text: type }
    }),
    getTrafficProviderPricingModels: (state, _, rootState) => state.trafficProviderPricingModels.map((type) => {
      const foundProviderMethodType = rootState.campaigns.pricingModels.find(
        (item) => item.value === type
      )
      return foundProviderMethodType ?? { value: type, label: type }
    }),
    getFlatDealAdFormats: (state, getter) => state.flatDealAdFormatIds.reduce((acc, id) => {
      const foundAdFormat = getter.getAdFormatsByAdNetwork.find((adFormat) => adFormat.id === id)
      if (foundAdFormat) {
        acc.push(foundAdFormat)
      }
      return acc
    }, [])
  }
}
