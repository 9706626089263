export default {
  install(_Vue) {
    const crisp = new _Vue({
      data() {
        return {
          inited: false
        }
      },
      methods: {
        setSettings({ websiteId, email, emailHash }) {
          window.$crisp = []
          window.$crisp.push(['set', 'user:email', [email, emailHash]])
          window.CRISP_WEBSITE_ID = websiteId
        },
        toggleChat() {
          window.$crisp.push(['do', 'chat:toggle'])
        },
        async init(crispSettings) {
          if (!this.inited) {
            this.setSettings(crispSettings)
            await this.loadScript(crispSettings)
            this.inited = true
          }
        },
        loadScript() {
          return new Promise((resolve) => {
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.async = true
            script.src = 'https://client.crisp.chat/l.js'
            document.getElementsByTagName('head')[0].appendChild(script)
            script.onload = resolve
          })
        }
      }
    })

    _Vue.prototype.$crisp = crisp
  }
}
