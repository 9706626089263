<template>
  <v-flex shrink>
    <v-menu offset-y>
      <template #activator="{ on }">
        <c-btn
          class="justify-center"
          icon
          :icon-props="{
            icon: '$user-settings',
            size: 18
          }"
          max-width="24"
          height="24"
          v-on="on"
        />
      </template>
      <v-list dense>
        <v-list-item v-if="adNetworkHasSectionProfile" :to="{ name: 'Personal' }">
          <v-icon class="mr-2" size="18">
            $config
          </v-icon>
          <v-list-item-title class="name">
            {{ user.name }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="$vuetify.breakpoint.xsOnly" @click="redirectAddFunds()">
          <v-icon class="mr-2" size="18">
            $credit-card
          </v-icon>
          <v-list-item-title>
            {{ balance }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="$vuetify.breakpoint.xsOnly && adNetworkHasSectionAddFunds"
          class="primary"
          @click="$router.push({ name: routeNames.ADD_FUNDS })"
        >
          <v-icon class="mr-2" color="white" size="18">
            $credit-card-plus
          </v-icon>
          <v-list-item-title class="white--text">
            {{ $t('main.sidebar.add_funds') }}
          </v-list-item-title>
        </v-list-item>

        <v-divider v-if="adNetworkHasSectionProfile" />

        <v-list-item :to="{ name: 'Logout' }">
          <v-icon class="mr-2" size="18">
            $channel
          </v-icon>
          <v-list-item-title>
            {{ isImpersonating ? 'Leave impersonate' : $t('main.exit') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-flex>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapState } from 'vuex'
  import guardSections from '@/types/guard-sections.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'DropdownMenu',
    components: {
      CBtn
    },
    data() {
      return {
        routeNames
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      user() {
        return this.$auth.user || {}
      },
      balance() {
        if (!this.user) {
          return '-'
        }
        const prefix = this.adNetwork.settings.currencySignIsShown ? '$' : ''
        return `${prefix} ${toFixedByDefault(this.user.balance)}`
      },
      isImpersonating() {
        return !!this.user.is_impersonating
      },
      adNetworkHasSectionAddFunds() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.ADD_FUNDS)
      },
      adNetworkHasSectionProfile() {
        return this.adNetwork.guardSections.some(({ name }) => name === guardSections.PROFILE)
      }
    },
    methods: {
      redirectAddFunds() {
        if (this.adNetworkHasSectionAddFunds) {
          this.$router.push({ name: routeNames.ADD_FUNDS })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    max-width: 300px;
  }
</style>
