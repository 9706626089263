import http from '../http.js'
import urlFormatter from '../url-formatter.js'
import ValidationError from '@/services/classes/validation-error.js'
import Site from '@/services/classes/Site.js'

const getURL = urlFormatter({
  findBlackWhiteList: '/api/private/v1/sites/search/domains',
  findBlackWhiteListById: '/api/private/v1/sites/search/ids',
  validateSites: '/api/private/v1/sites/validate'
})

export default {
  async findPremium({
    adFormatId,
    pricingModel,
    placementTypesIds = [],
    trafficTypeId = null,
    categoryIds = [],
    categoryGroupIds = [],
    enableCancelToken = true
  }) {
    if (enableCancelToken) this.findPremium.abort?.()

    const response = await http.get('/api/private/v1/sites/premium', {
      params: {
        ad_format_id: adFormatId,
        pricing_model: pricingModel,
        targets: {
          category_group_target: { category_group_ids: categoryGroupIds },
          category_target: { category_ids: categoryIds },
          traffic_type_target: { traffic_type_id: trafficTypeId },
          placement_type_target: { placement_type_ids: placementTypesIds }
        }
      },
      ...enableCancelToken && {
        cancelToken: new http.CancelToken((abort) => {
          this.findPremium.abort = abort
        })
      }
    })
    return response.data.data.map((site) => new Site(site))
  },
  findBlackWhiteList({ query, marketplace }) {
    const endpoint = getURL('findBlackWhiteList')
    return http
      .post(endpoint, {
        query,
        marketplace
      })
      .then((response) => response.data.data.map((site) => new Site(site)))
  },
  async searchById(value) {
    const endpoint = getURL('findBlackWhiteListById')
    const response = await http.post(endpoint, value)
    return response?.data.data.map((site) => new Site(site))
  },
  validateSites(query) {
    const endpoint = getURL('validateSites')
    return http
      .post(endpoint, { domains: query })
      .then((response) => ({
        sites: response.data.sites,
        invalidDomains: response.data.invalid_domains
      }))
      .catch((error) => {
        const errors = error.response?.data?.errors
        if (errors) {
          throw new ValidationError({
            domains: errors.domains
          })
        } else {
          throw error
        }
      })
  },
  marketplaceList: async () => {
    const { data } = await http.get('/api/private/v1/sites/marketplace')
    return data.data
  },
  getURL
}
